<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">

            <v-row align-items="center">

              <v-col class="flex_end">
                <v-btn
                  color="primary"
                  depressed
                  tile
                  @click="addIssue()"
                >
                  Dodaj zgłoszenie
                </v-btn>
              </v-col>
            </v-row>

        </v-card>

        <v-card>
          <v-card-text>

            <h2>Którego projektu dotyczy problem?</h2>

            <v-form
              ref="form" 
              v-model="valid"
              lazy-validation
            >

              <v-autocomplete
                v-model="selected_project"
                :items="projects"
                item-text="name"
                item-value="id"
                no-data-text="Brak wyników"
                label="Wybierz projekt"
                class="classic_select"
                :rules="$store.state.rules.not_null"
              ></v-autocomplete>

              <v-switch
                v-model="priority"
                inset
                :label="'Piorytet: ' + (priority?'ASAP':'Normalny')"
              ></v-switch>

              <v-text-field
                v-model="name"
                label="Tytul błędu"
                :rules="$store.state.rules.not_null"
              ></v-text-field>

              <v-textarea
                v-model="description"
                label="Opis błędu"
                filled
                :rules="$store.state.rules.not_null"
              ></v-textarea>

              <v-file-input
                v-model="files"
                truncate-length="15"
                chips
                multiple
                label="Załącz pliki"
              ></v-file-input>

            </v-form>

          </v-card-text>
        </v-card>
        

      </v-col>
    </v-row>
    
  </div>
</template>

<script>
export default {
  data: () => ({
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Nowe zgłoszenie',
        disabled: true,
        to: '/zgloszenie-nowe',
      },
    ],

    name: '',
    description: '',

    files: null,

    priority: false,
    selected_project: 0,
    projects: [],
  }),
  methods: {
    addIssue(){
      if(this.$refs.form.validate()) {
        this.$store.commit('loader');

        let formData = new FormData();
        formData.append("name", this.name);
        formData.append("description", this.description);
        if(this.priority) formData.append("priority", 'asap');
        else formData.append("priority", 'normalny');
        formData.append("project_id", this.selected_project);
        formData.append("type", 'nowy');

        if(this.files){
          for(let file in this.files){
            formData.append("files["+file+"][file]", this.files[file]);
          }
        }

        this.$axios({url: this.$store.state.api +'/issue', data: formData, method: 'POST' })
        .then(resp => {
          console.log(resp.data);
          this.$router.push('/zgloszenia');
          // this.users = resp.data;

            this.$store.commit("snackbar", {
              text: "Dodano zgłoszenie",
              color: "primary",
              btn_color: "white"
            });

          this.$store.commit('loader_off');
        })
        .catch(err => {
            this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
      } else this.$store.commit('wrong_form_snackbar');
    },
  },
  mounted(){
    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/project', data: {}, method: 'GET' })
      .then(resp => {
        this.projects = resp.data;
        // this.attached_files = resp.data.files;
        console.log(resp.data);
        // this.user_data = resp.data.user_assign;
        this.$store.commit('loader_off');
      })
      .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
  }
};
</script>

<style lang="scss" scoped>
.card_section {
  margin-top: 25px;
}
.checkbox {
  margin-top: 0;
}
</style>